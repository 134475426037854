.user {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 3px 10px 3px 3px;
  border-radius: 15px;
  color: white;
  height: 31px;

  img,svg {
    width: 25px;
    height: 25px;
    border-radius: 15px;
  }

  background-color: var(--bg);
  cursor: default;

  &:hover {
    background-color: var(--dark);
  }

}