.upload-payments {


    .file {
      width: 600px;
      height: 200px;
    }
    .drop {
      width: 600px;
      height: 200px;
    }
}