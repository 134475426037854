.view-project {
  .content {
    width: 80vw;
    height: 80vh;
    align-items: flex-start;

    .prompt {
      .comment {
        margin-left: 15px;
        position: relative;
        cursor: pointer;
      
        .badge {
          display: inline-flex;
          position: absolute;
          background-color: red;
          color: white;
          font-size: 12px;
          font-weight: bold;
          border-radius: 10px;
          width: 15px;
          height: 15px;
          justify-content: center;
          left: -5px;
          top: 0;
        }
      }
    }

    .details {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .assignees {
      display: grid;
      grid-template-columns: 0fr 1fr;
      gap: 10px;
    }

    .editor {
      width: 100%;

      .quill {
        width: 100%;
        height: 200px;
        padding-bottom: 42px;
      }

      .text-box {
        width: 100%;
        height: 200px;
        font-family: Helvetica, Arial, sans-serif ;
        font-size: 13px;
        border: 1px solid #ccc;
        overflow-y: scroll;
      }
    }

    .recording {
      .recordings {
        display: flex;
        flex-direction: column-reverse;
        gap: 5px;

        .revision {
          display: flex;
          gap: 5px;
          align-items: center;
        }
      }
    }

    .attachments {
      .files {
        display: flex;
      }
    }

    .show-all {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}