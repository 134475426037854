.login {
  font-family: "Nunito Sans", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .video {
    position: fixed;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
  }

  .dialog {
    width: 800px;
    height: 600px;
    z-index: 1;
    background: white;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;

    .title {
      display: flex;
      align-items: center;
      font-size: 48px;
      font-weight: bold;
      gap: 5px;
    }

    .error {
      color: maroon;
      height: 20px;
    }
  }

  .credits {
    position: absolute;
    bottom: 20px;
    right: 20px;

    .pexels {
      height: 50px;
    }

    color:white;
    a {
      color: white;
    }
  }

}