.comments {
  .content {
    height: 80vh;
    overflow-y: scroll;
    .comment {
      .text-box {
        width: 100%;
        height: unset;
        font-family: Helvetica, Arial, sans-serif ;
        font-size: 13px;
        border: 1px solid #ccc;
        overflow-y: scroll;
        margin-bottom: 8px;
      }
      .details {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
    .editor {
      height: 200px;
      padding-bottom: 42px;
    }
  }
}