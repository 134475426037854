.users {
    display: grid;
    grid-template-columns: 40px 150px auto;
    grid-column-gap: 10px;
    padding: 20px;

    .user-row {
        display: grid;
        grid-column: 1/4;
        grid-template-columns: subgrid;
        align-items: center;
        
        .image {
            img {
                width: 40px;
                height: 40px;
                border-radius: 20px;
            }
        }
    }

}