.empty {
  font-size: 24px;
  text-align: center;
  padding-top: 100px;
  .link {
    cursor: pointer;
    color: #5338F5;
    text-decoration: underline;
  }
}

.projects {
  display: grid;
  margin: 15px 0;
  grid-column-gap: 10px;
  grid-template-columns: auto auto 1fr repeat(4, auto) 50px;

  .row {
    display: grid;
    grid-column: 1/9;
    grid-template-columns: subgrid;
    align-items: center;
    border-bottom: 1px solid lightgray;
    padding: 10px 15px;

    &.d {
      cursor: pointer;
      &:hover {
        .actions {
          visibility: unset;
        }
        box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.2), inset -1px 0 0 rgba(255, 255, 255, 0.2), 0 0 4px 0 rgba(95, 99, 104, .6), 0 0 6px 2px rgba(95, 99, 104, .6);
      }
    }

    .track {
      display: flex;
    }

    .state {
      display: flex;
    }

    .title {
      position: relative;
      .badge {
        display: inline-flex;
        position: absolute;
        background-color: red;
        color: white;
        font-size: 12px;
        font-weight: bold;
        border-radius: 10px;
        width: 15px;
        height: 15px;
        justify-content: center;
        left: -15px;
        top: -5px;
      }
    }

    .assignee {
      display: flex;
    }

    .actions {
      display: flex;
      gap: 3px;
      visibility: hidden;
      position: absolute;
      right: 10px;
      background: white;
      padding: 5px;

      svg {
        cursor: pointer;
        opacity: 50%;

        &:hover {
          opacity: 100%;
        }

        &.disabled {
          opacity: 10%;
          cursor: default;
        }
      }

    }
  }

  .h {
    div {
      height: 24px;
      display: flex;
      .sort {
        cursor: pointer;
        visibility: hidden;
        opacity: 0.5;

        &.active {
          visibility: unset;
          opacity: 1;
        }
      }
      &:hover {
        .sort {
          visibility: unset;
          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .actions {
      visibility: unset;

      .animate {
        animation: rotate 1s infinite;
      }
    }
  }
}

@keyframes rotate {
  0% {transform: rotate(0deg)}
  100% {transform: rotate(360deg)}
}
