* {
    box-sizing: border-box;
}

.App {
    font-family: "Nunito Sans", sans-serif;
}

.loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    font-weight: bold;
}
