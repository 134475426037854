.bubble {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 3px 8px;
  border-radius: 15px;
  color: white;
  background-color: var(--bg);
  cursor: default;

  &:hover {
    background-color: var(--dark);
  }
}