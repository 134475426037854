.step {
  .content {
    .assign {
      display: flex;
      align-items: center;
      gap: 5px;
    }
    .editor {
      display: flex;
      flex-direction: column;
      width: 600px;
      height: 400px;
      .quill {
        flex-grow: 1;
        padding-bottom: 42px;
      }
    }
    .drop {
      width: 600px;
      height: 200px;
    }
  }
}