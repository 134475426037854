.payments {
    padding: 20px;
    .invoice {
        margin-bottom: 20px;
        display: flex;
        gap: 10px;
    }
    .table {
        display: grid;
        grid-column-gap: 10px;
        grid-template-columns: repeat(6, auto);
      
        .row {
          display: grid;
          grid-column: 1/7;
          grid-template-columns: subgrid;

          &.even {
            background-color: linen;
          }

          div {
            display: flex;
          }

          .name {
            cursor: pointer;
            .expand {
                transition: all 0.25s ease-in-out;
                &.open {
                    transform: rotate(-180deg);
                }
            }
          }
          .pay {
            cursor: pointer;
          }
        }
    }
}