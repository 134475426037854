.attachments {

  .files {
    display: flex;
  }

  .drop {
    width: 600px;
    height: 150px;
  }
}