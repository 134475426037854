.invoice-dialog {
    .content {
        font-family: Arial, Helvetica, sans-serif;
        display: flex;
        flex-direction: column;
        .heading {
            font-size: 24px;
            font-weight: bold;
            color: #c0c0c0;
            align-self: center
        }
        .details {
            display: flex;
            justify-content: space-between;

            .talent-name {
                font-weight: bold;
            }
            .invoice-details {
                display: grid;
                grid-template-columns: repeat(2, auto);
                gap: 2px;
                .invoice-total {
                    background-color: #c0c0c0;
                }
                div {
                    padding: 3px;
                }
            }
        }
        .bill-to {
            .title {
                font-weight: bold;
                text-decoration: underline;
                margin-bottom: 16px;
            }
        }
        table {
            border-collapse: collapse;
            td,th {
                border: 2px solid black;
                width: 135px;
                text-align: center;
                padding: 5px;

                &.title {
                    width: 500px;
                }
            }
        }
        .due {
            align-self: start;
        }
        .contents {
            .total-title {
                border: unset;
                text-align: left;
            }
        }
    }
}