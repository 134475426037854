.users-dropdown {
  position: relative;
  cursor: pointer;

  .list {
    position: fixed;
    display: flex;
    visibility: hidden;
    flex-direction: column;
    gap: 5px;
    box-shadow: 0 2px 4px black;
    background: white;
    z-index: 1;
    padding: 5px;
    
    &.show {
      visibility: unset;
    }
  }
}