.drop {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px dashed gray;
  border-radius: 4px;
  cursor: pointer;

  &.red {
    border: 4px dashed red;
  }
  &.green {
    border: 4px dashed green;
  }
}