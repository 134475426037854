.sl-loader {
  transform-origin: 80.5px 80.5px;
  animation: animation 5s infinite;
  .b {transform-origin: 80.5px 80.5px}

  #b1 {animation: animation-b1 5s infinite;}
  #b2 {animation: animation-b2 5s infinite;}
  #b3 {animation: animation-b3 5s infinite;}
  #b4 {animation: animation-b4 5s infinite;}

  @keyframes animation {
    50% {transform: rotate(0deg)}
    75% {transform: rotate(360deg)}
    100% {transform: rotate(360deg)}
  }

  @keyframes animation-b1 {
    0% {transform: translate(-2.5px, -57px);}
    25% {transform: translate(0, 0) rotate(45deg);}
    50% {transform: translate(0, -55px) rotate(0deg)}
    75% {transform: translate(-25px, 0px) rotate(-90deg)}
    100% {transform: translate(-2.5px, -57px) rotate(0deg);}
  }

  @keyframes animation-b2 {
    0% {transform: translate(-17.5px, -29px);}
    25% {transform: translate(0, 0) rotate(90deg);}
    50% {transform: translate(-55px, 0px) rotate(90deg)}
    75% {transform: translate(0px, 25px) rotate(0deg)}
    100% {transform: translate(-17.5px, -29px) rotate(0deg);}
  }

  @keyframes animation-b3 {
    0% {transform: translate(-2.5px, -1px);}
    25% {transform: translate(0, 0) rotate(135deg);}
    50% {transform: translate(0px, 55px) rotate(180deg)}
    75% {transform: translate(25px, 0px) rotate(90deg)}
    100% {transform: translate(-2.5px, -1px) rotate(0deg);}
  }

  @keyframes animation-b4 {
    0% {transform: translate(-17.5px, 27px);}
    25% {transform: translate(0, 0) rotate(180deg);}
    50% {transform: translate(55px, 0px) rotate(270deg)}
    75% {transform: translate(0, -25px) rotate(180deg)}
    100% {transform: translate(-17.5px, 27px) rotate(360deg);}
  }
}