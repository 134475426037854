.new-project {
  .content {
    width: 80vw;
    height: 80vh;

    .title {
      height: 32px;
      font-size: 20px;
    }

    .brief {
      flex-grow: 1;
      padding-bottom: 42px;
    }

    .attachments {
      display: flex;
    }

    .drop {
      height: 200px;
    }
  }
}