.file-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75px;

  svg {
    width: 75px;
    height: 75px;
    cursor: pointer;
  }

  .name {
    width: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid transparent;
  }

  &:hover {
    .name {
      overflow: unset;
      background: white;
      border: 1px solid black;
      border-radius: 5px;
      padding: 0 5px;
      z-index: 1;
      width: auto;
    }
  }
}
